import {
  IConsumption,
  IConsumptionFilter,
  IContract,
  IContractDetails,
  IContractDraftDeliveryPointsPayload,
  IContractsFilter,
  ICreateContractDraftPayload,
  ICreateCustomerContactsPayload,
  IEic,
  IEicFilter,
  IGetConsumptionByEicPayload,
  IGetSpotPricesPayload,
  IInvoice,
  IInvoicesFilter,
  PromiseAction,
  TableSort
} from 'src/shared/interfaces'
import * as TradersApi from 'src/apis/traders.api'

export const GET_TRADER_CONTRACTS_TRIGGERED = 'GET_TRADER_CONTRACTS_TRIGGERED'
export const GET_TRADER_CONTRACTS_SUCCESS = 'GET_TRADER_CONTRACTS_SUCCESS'
export const GET_TRADER_CONTRACTS_FAILURE = 'GET_TRADER_CONTRACTS_FAILURE'

export function getTraderContracts(
  filter: IContractsFilter,
  sort: TableSort<IContract> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = TradersApi.getTraderAllContracts(filter, sort, page, size)
  return {
    onRequest: GET_TRADER_CONTRACTS_TRIGGERED,
    onSuccess: GET_TRADER_CONTRACTS_SUCCESS,
    onFailure: GET_TRADER_CONTRACTS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

export const GET_TRADER_DRAFTS_CONTRACTS_TRIGGERED = 'GET_TRADER_DRAFTS_CONTRACTS_TRIGGERED'
export const GET_TRADER_DRAFTS_CONTRACTS_SUCCESS = 'GET_TRADER_DRAFTS_CONTRACTS_SUCCESS'
export const GET_TRADER_DRAFTS_CONTRACTS_FAILURE = 'GET_TRADER_DRAFTS_CONTRACTS_FAILURE'

export function getTraderDraftsContracts(
  filter: IContractsFilter,
  sort: TableSort<IContract> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = TradersApi.getTraderDraftsContracts(filter, sort, page, size)
  return {
    onRequest: GET_TRADER_DRAFTS_CONTRACTS_TRIGGERED,
    onSuccess: GET_TRADER_DRAFTS_CONTRACTS_SUCCESS,
    onFailure: GET_TRADER_DRAFTS_CONTRACTS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

export const GET_TRADER_CONTRACTS_STATUSES_TRIGGERED = 'GET_TRADER_CONTRACTS_STATUSES_TRIGGERED'
export const GET_TRADER_CONTRACTS_STATUSES_SUCCESS = 'GET_TRADER_CONTRACTS_STATUSES_SUCCESS'
export const GET_TRADER_CONTRACTS_STATUSES_FAILURE = 'GET_TRADER_CONTRACTS_STATUSES_FAILURE'

export const getTraderContractsStatuses = () => {
  const promise = TradersApi.getTraderContractsStatuses()
  return {
    onRequest: GET_TRADER_CONTRACTS_STATUSES_TRIGGERED,
    onSuccess: GET_TRADER_CONTRACTS_STATUSES_SUCCESS,
    onFailure: GET_TRADER_CONTRACTS_STATUSES_FAILURE,
    promise
  }
}

export const CREATE_TRADER_CONTRACT_TRIGGERED = 'CREATE_TRADER_CONTRACT_TRIGGERED'
export const CREATE_TRADER_CONTRACT_SUCCESS = 'CREATE_TRADER_CONTRACT_SUCCESS'
export const CREATE_TRADER_CONTRACT_FAILURE = 'CREATE_TRADER_CONTRACT_FAILURE'

export function createTraderContract(values: ICreateContractDraftPayload): PromiseAction {
  const promise = TradersApi.createTraderContract(values)
  return {
    onRequest: CREATE_TRADER_CONTRACT_TRIGGERED,
    onSuccess: CREATE_TRADER_CONTRACT_SUCCESS,
    onFailure: CREATE_TRADER_CONTRACT_FAILURE,
    promise,
    payload: { values }
  }
}

export const UPDATE_TRADER_CONTRACT_DETAIL_TRIGGERED = 'UPDATE_TRADER_CONTRACT_DETAIL_TRIGGERED'
export const UPDATE_TRADER_CONTRACT_DETAIL_SUCCESS = 'UPDATE_TRADER_CONTRACT_DETAIL_SUCCESS'
export const UPDATE_TRADER_CONTRACT_DETAIL_FAILURE = 'UPDATE_TRADER_CONTRACT_DETAIL_FAILURE'

export function updateTraderContractDetail(contractId: number, values: Partial<IContractDetails>): PromiseAction {
  const promise = TradersApi.updateTraderContractDetail(contractId, values)
  return {
    onRequest: UPDATE_TRADER_CONTRACT_DETAIL_TRIGGERED,
    onSuccess: UPDATE_TRADER_CONTRACT_DETAIL_SUCCESS,
    onFailure: UPDATE_TRADER_CONTRACT_DETAIL_FAILURE,
    promise,
    payload: { contractId, values }
  }
}

export const GET_TRADER_CONTRACT_DETAIL_TRIGGERED = 'GET_TRADER_CONTRACT_DETAIL_TRIGGERED'
export const GET_TRADER_CONTRACT_DETAIL_SUCCESS = 'GET_TRADER_CONTRACT_DETAIL_SUCCESS'
export const GET_TRADER_CONTRACT_DETAIL_FAILURE = 'GET_TRADER_CONTRACT_DETAIL_FAILURE'

export function getTraderContractDetail(contractId: number | string, amendmentId?: number): PromiseAction {
  const promise = TradersApi.getTraderContractDetail(contractId, amendmentId)
  return {
    onRequest: GET_TRADER_CONTRACT_DETAIL_TRIGGERED,
    onSuccess: GET_TRADER_CONTRACT_DETAIL_SUCCESS,
    onFailure: GET_TRADER_CONTRACT_DETAIL_FAILURE,
    promise,
    payload: { contractId }
  }
}

export const GET_TRADER_ELECTRICITY_CUSTOMERS_TRIGGERED = 'GET_TRADER_ELECTRICITY_CUSTOMERS_TRIGGERED'
export const GET_TRADER_ELECTRICITY_CUSTOMERS_SUCCESS = 'GET_TRADER_ELECTRICITY_CUSTOMERS_SUCCESS'
export const GET_TRADER_ELECTRICITY_CUSTOMERS_FAILURE = 'GET_TRADER_ELECTRICITY_CUSTOMERS_FAILURE'

export const getTraderElectricityCustomers = (role_id: number): PromiseAction => {
  const promise = TradersApi.getTraderElectricityCustomers(role_id)
  return {
    onRequest: GET_TRADER_ELECTRICITY_CUSTOMERS_TRIGGERED,
    onSuccess: GET_TRADER_ELECTRICITY_CUSTOMERS_SUCCESS,
    onFailure: GET_TRADER_ELECTRICITY_CUSTOMERS_FAILURE,
    promise
  }
}

export const GET_TRADER_AVAILABLE_EIC_LIST_TRIGGERED = 'GET_TRADER_AVAILABLE_EIC_LIST_TRIGGERED'
export const GET_TRADER_AVAILABLE_EIC_LIST_SUCCESS = 'GET_TRADER_AVAILABLE_EIC_LIST_SUCCESS'
export const GET_TRADER_AVAILABLE_EIC_LIST_FAILURE = 'GET_TRADER_AVAILABLE_EIC_LIST_FAILURE'

export const getTraderAvailableEicList = (fromDate: string): PromiseAction => {
  const promise = TradersApi.getTraderAvailableEicList(fromDate)
  return {
    onRequest: GET_TRADER_AVAILABLE_EIC_LIST_TRIGGERED,
    onSuccess: GET_TRADER_AVAILABLE_EIC_LIST_SUCCESS,
    onFailure: GET_TRADER_AVAILABLE_EIC_LIST_FAILURE,
    promise
  }
}

export const GET_TRADER_EIC_TRIGGERED = 'GET_TRADER_EIC_TRIGGERED'
export const GET_TRADER_EIC_SUCCESS = 'GET_TRADER_EIC_SUCCESS'
export const GET_TRADER_EIC_FAILURE = 'GET_TRADER_EIC_FAILURE'

export function getTraderEic(
  filter: IEicFilter,
  sort: TableSort<IEic> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = TradersApi.getTraderAllEic(filter, sort, page, size)
  return {
    onRequest: GET_TRADER_EIC_TRIGGERED,
    onSuccess: GET_TRADER_EIC_SUCCESS,
    onFailure: GET_TRADER_EIC_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get paginated trader invoices
export const GET_TRADER_ALL_INVOICES_TRIGGERED = 'GET_TRADER_ALL_INVOICES_TRIGGERED'
export const GET_TRADER_ALL_INVOICES_SUCCESS = 'GET_TRADER_ALL_INVOICES_SUCCESS'
export const GET_TRADER_ALL_INVOICES_FAILURE = 'GET_TRADER_ALL_INVOICES_FAILURE'

export function getTraderAllInvoices(
  filter: IInvoicesFilter,
  sort: TableSort<IInvoice> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = TradersApi.getTraderAllInvoices(filter, sort, page, size)
  return {
    onRequest: GET_TRADER_ALL_INVOICES_TRIGGERED,
    onSuccess: GET_TRADER_ALL_INVOICES_SUCCESS,
    onFailure: GET_TRADER_ALL_INVOICES_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

// Get trader spot prices
export const GET_TRADER_SPOT_PRICES_TRIGGERED = 'GET_TRADER_SPOT_PRICES_TRIGGERED'
export const GET_TRADER_SPOT_PRICES_SUCCESS = 'GET_TRADER_SPOT_PRICES_SUCCESS'
export const GET_TRADER_SPOT_PRICES_FAILURE = 'GET_TRADER_SPOT_PRICES_FAILURE'

export function getTraderSpotPrices(payload: IGetSpotPricesPayload): PromiseAction {
  const promise = TradersApi.getTraderSpotPrices(payload)
  return {
    onRequest: GET_TRADER_SPOT_PRICES_TRIGGERED,
    onSuccess: GET_TRADER_SPOT_PRICES_SUCCESS,
    onFailure: GET_TRADER_SPOT_PRICES_FAILURE,
    promise
  }
}

// Fetch Consumption list for trader
export const GET_TRADER_CONSUMPTIONS_TRIGGERED = 'GET_TRADER_CONSUMPTIONS_TRIGGERED'
export const GET_TRADER_CONSUMPTIONS_SUCCESS = 'GET_TRADER_CONSUMPTIONS_SUCCESS'
export const GET_TRADER_CONSUMPTIONS_FAILURE = 'GET_TRADER_CONSUMPTIONS_FAILURE'

export function getTraderConsumptions(
  filter: IConsumptionFilter,
  sort: TableSort<IConsumption> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = TradersApi.getTraderAllConsumption(filter, sort, page, size)
  return {
    onRequest: GET_TRADER_CONSUMPTIONS_TRIGGERED,
    onSuccess: GET_TRADER_CONSUMPTIONS_SUCCESS,
    onFailure: GET_TRADER_CONSUMPTIONS_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

// Fetch Consumption detail by client
export const GET_CONSUMPTION_BY_EIC_BY_TRADER_TRIGGERED = 'GET_CONSUMPTION_BY_EIC_BY_TRADER_TRIGGERED'
export const GET_CONSUMPTION_BY_EIC_BY_TRADER_SUCCESS = 'GET_CONSUMPTION_BY_EIC_BY_TRADER_SUCCESS'
export const GET_CONSUMPTION_BY_EIC_BY_TRADER_FAILURE = 'GET_CONSUMPTION_BY_EIC_BY_TRADER_FAILURE'

export function getConsumptionByEicByTrader(eic: string, params: IGetConsumptionByEicPayload): PromiseAction {
  const promise = TradersApi.getConsumptionByEicByTrader(eic, params)
  return {
    onRequest: GET_CONSUMPTION_BY_EIC_BY_TRADER_TRIGGERED,
    onSuccess: GET_CONSUMPTION_BY_EIC_BY_TRADER_SUCCESS,
    onFailure: GET_CONSUMPTION_BY_EIC_BY_TRADER_FAILURE,
    promise
  }
}

// Get eic details by trader
export const GET_EIC_DETAIL_BY_TRADER_TRIGGERED = 'GET_EIC_DETAIL_BY_TRADER_TRIGGERED'
export const GET_EIC_DETAIL_BY_TRADER_SUCCESS = 'GET_EIC_DETAIL_BY_TRADER_SUCCESS'
export const GET_EIC_DETAIL_BY_TRADER_FAILURE = 'GET_EIC_DETAIL_BY_TRADER_FAILURE'

let getEicDetailController: AbortController
export function getEicDetailByTrader(eicId: number | string): PromiseAction {
  if (getEicDetailController) getEicDetailController.abort()
  getEicDetailController = new AbortController()
  const promise = TradersApi.getEicDetailByTrader(eicId, getEicDetailController.signal)
  return {
    onRequest: GET_EIC_DETAIL_BY_TRADER_TRIGGERED,
    onSuccess: GET_EIC_DETAIL_BY_TRADER_SUCCESS,
    onFailure: GET_EIC_DETAIL_BY_TRADER_FAILURE,
    promise,
    payload: { eicId }
  }
}

// Download pdf invoice
export const GET_TRADER_PDF_INVOICE_TRIGGERED = 'GET_PDF_INVOIGET_TRADER_PDF_INVOICE_TRIGGEREDCE_TRIGGERED'
export const GET_TRADER_PDF_INVOICE_SUCCESS = 'GET_TRADER_PDF_INVOICE_SUCCESS'
export const GET_TRADER_PDF_INVOICE_FAILURE = 'GET_TRADER_PDF_INVOICE_FAILURE'

export function getTraderPdfInvoice(id: number): PromiseAction {
  const promise = TradersApi.getTraderPdfInvoice(id)
  return {
    onRequest: GET_TRADER_PDF_INVOICE_TRIGGERED,
    onSuccess: GET_TRADER_PDF_INVOICE_SUCCESS,
    onFailure: GET_TRADER_PDF_INVOICE_FAILURE,
    promise
  }
}

export const TRADER_POST_CONTRACT_DELIVERY_POINTS_TRIGGERED = 'TRADER_POST_CONTRACT_DELIVERY_POINTS_TRIGGERED'
export const TRADER_POST_CONTRACT_DELIVERY_POINTS_SUCCESS = 'TRADER_POST_CONTRACT_DELIVERY_POINTS_SUCCESS'
export const TRADER_POST_CONTRACT_DELIVERY_POINTS_FAILURE = 'TRADER_POST_CONTRACT_DELIVERY_POINTS_FAILURE'

export function traderPostContractDeliveryPoints(
  contractId: number,
  payload: IContractDraftDeliveryPointsPayload
): PromiseAction {
  const promise = TradersApi.traderPostContractDeliveryPoints(contractId, payload)
  return {
    onRequest: TRADER_POST_CONTRACT_DELIVERY_POINTS_TRIGGERED,
    onSuccess: TRADER_POST_CONTRACT_DELIVERY_POINTS_SUCCESS,
    onFailure: TRADER_POST_CONTRACT_DELIVERY_POINTS_FAILURE,
    promise,
    payload: { contractId, payload }
  }
}

export const GET_TRADER_CONTRACT_NUMBER_TRIGGERED = 'GET_TRADER_CONTRACT_NUMBER_TRIGGERED'
export const GET_TRADER_CONTRACT_NUMBER_SUCCESS = 'GET_TRADER_CONTRACT_NUMBER_SUCCESS'
export const GET_TRADER_CONTRACT_NUMBER_FAILURE = 'GET_TRADER_CONTRACT_NUMBER_FAILURE'

export function getTraderContractNumber(): PromiseAction {
  const promise = TradersApi.getTraderContractNumber()
  return {
    onRequest: GET_TRADER_CONTRACT_NUMBER_TRIGGERED,
    onSuccess: GET_TRADER_CONTRACT_NUMBER_SUCCESS,
    onFailure: GET_TRADER_CONTRACT_NUMBER_FAILURE,
    promise
  }
}

export const GENERATE_TRADER_DOC_CONTRACT_TRIGGERED = 'GENERATE_TRADER_DOC_CONTRACT_TRIGGERED'
export const GENERATE_TRADER_DOC_CONTRACT_SUCCESS = 'GENERATE_TRADER_DOC_CONTRACT_SUCCESS'
export const GENERATE_TRADER_DOC_CONTRACT_FAILURE = 'GENERATE_TRADER_DOC_CONTRACT_FAILURE'

export function generateTraderDocContract(
  contractNumber: string,
  values: ICreateCustomerContactsPayload
): PromiseAction {
  const promise = TradersApi.generateTraderDocContract(contractNumber, values)
  return {
    onRequest: GENERATE_TRADER_DOC_CONTRACT_TRIGGERED,
    onSuccess: GENERATE_TRADER_DOC_CONTRACT_SUCCESS,
    onFailure: GENERATE_TRADER_DOC_CONTRACT_FAILURE,
    promise
  }
}
