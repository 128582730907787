import moment from 'moment'
import {
  IConsumption,
  IConsumptionFilter,
  IConsumptionForEic,
  IContract,
  IContractDetails,
  IContractDraftDeliveryPointsPayload,
  IContractsFilter,
  IContractStatus,
  ICreateContractDraftPayload,
  ICreateCustomerContactsPayload,
  IEic,
  IEicDetails,
  IEicFilter,
  IEicSimpleListItem,
  IGetConsumptionByEicPayload,
  IGetSpotPricesPayload,
  IInvoice,
  IInvoicesFilter,
  IPagination,
  IPaginationResponse,
  IPaginationWSummaryResponse,
  ISetContractNumberResponse,
  ISimpleCustomerListItem,
  ISpotPrice,
  TableSort
} from 'src/shared/interfaces'
import { generatePaginationPayload } from 'src/lib/paginationPayload'

import Http from './api'

import { DATE_FORMAT_DB } from '../shared/constants'

export const getTraderAllContracts = (
  filter: IContractsFilter,
  sort: TableSort<IContract> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      status: filter.status,
      unprocessed_amendments: filter.unprocessed_amendments
    },
    searchBy: ['customer', 'contract_number'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IContract>>('/traders/contracts', payload)
}

export const getTraderDraftsContracts = (
  filter: IContractsFilter,
  sort: TableSort<IContract> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      status: filter.status,
      unprocessed_amendments: filter.unprocessed_amendments
    },
    searchBy: ['customer', 'contract_number'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IContract>>('/traders/contracts/drafts', payload)
}

export const getTraderContractsStatuses = () => {
  return Http.get<IContractStatus[]>(`/traders/contracts/status`)
}

export const createTraderContract = (payload: ICreateContractDraftPayload) => {
  return Http.post<IContractDetails>('/traders/contracts/create', payload)
}

export const updateTraderContractDetail = (id: number, payload: Partial<IContractDetails>) => {
  return Http.patch<IContractDetails>(`/traders/contracts/${id}`, payload)
}

export const getTraderContractDetail = (id: number | string, amendmentId?: number) => {
  return Http.get<IContractDetails>(`/traders/contracts/${id}`, { amendmentId })
}

export const getTraderElectricityCustomers = (role_id: number) => {
  return Http.get<ISimpleCustomerListItem[]>(`/traders/contracts/customers-by-role/${role_id}`)
}

export const getTraderAvailableEicList = (fromDate: string) => {
  return Http.get<IEicSimpleListItem[]>(`/traders/contracts/available-eic-list/${fromDate}`)
}

export const getTraderAllEic = (filter: IEicFilter, sort: TableSort<IEic> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_start: [filter.supply_start_date_from, filter.supply_start_date_to],
      rzm: [filter.rzm_from, filter.rzm_to],
      status: filter.status,
      rate: filter.rate
    },
    searchBy: ['eic', 'customer'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationWSummaryResponse<IEic>>('/traders/eic', payload)
}

export const getTraderAllInvoices = (
  filter: IInvoicesFilter,
  sort: TableSort<IInvoice> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_period_start: [
        filter.delivery_period_start_from
          ? moment(filter.delivery_period_start_from).format(DATE_FORMAT_DB)
          : undefined,
        filter.delivery_period_start_to ? moment(filter.delivery_period_start_to).format(DATE_FORMAT_DB) : undefined
      ],
      type_of_invoice: filter.type_of_invoice,
      status: filter.status
    },
    searchBy: ['customer', 'variable_symbol'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IInvoice>>('/traders/invoices', payload)
}

export const getTraderSpotPrices = (params: IGetSpotPricesPayload) => {
  return Http.get<ISpotPrice>('/traders/spot-prices', params)
}

export const getTraderAllConsumption = (
  filter: IConsumptionFilter,
  sort: TableSort<IConsumption> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      'eic.delivery_start': [filter.delivery_start_date_from, filter.delivery_start_date_to],
      'eic.status': filter.status
    },
    searchBy: ['eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPagination<IConsumption>>('/traders/consumptions', payload)
}

export const getConsumptionByEicByTrader = (id: string, params: IGetConsumptionByEicPayload) => {
  return Http.get<IConsumptionForEic[]>(`/traders/consumptions/${id}`, params)
}

export const getEicDetailByTrader = (id: number | string, signal?: AbortSignal) => {
  return Http.get<IEicDetails>(`/traders/eic/${id}`, {}, signal)
}

export const getTraderPdfInvoice = (id: number) => {
  return Http.getFile<unknown>(`/traders/invoices/${id}/pdf`)
}

export const traderPostContractDeliveryPoints = (id: number, payload: IContractDraftDeliveryPointsPayload) => {
  return Http.post<unknown>(`/traders/contracts/${id}/delivery-points`, payload)
}

export const getTraderContractNumber = () => {
  return Http.get<ISetContractNumberResponse>(`/traders/contracts/suggest-contract-number`)
}

export const generateTraderDocContract = (contractNumber: string, values: ICreateCustomerContactsPayload) => {
  const queryParams = new URLSearchParams(values as any).toString()

  return Http.getFile<unknown>(`/traders/contracts/docx/generate/${contractNumber}?${queryParams}`)
}
